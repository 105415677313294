<template>
  <van-overlay class-name="zddInvitPopup" :show="show">
    <div class="wrapper">
      <div class="handForm">
        <div class="hand">
          <img src="@/assets/imgs/zdd/hand.png" alt="" />
          <div>通过微信分享</div>
        </div>
      </div>
      <div class="contentForm">
        <div class="invitBg">
          <img src="@/assets/imgs/zdd/invitBg.png" alt="" />
        </div>
        <div class="content">
          <div class="title">活动时间</div>
          <div class="time">2021年9月20日 至 2021年10月20日</div>
          <div class="title">活动流程</div>
          <ul class="process">
            <li>
              <img src="@/assets/imgs/zdd/first.png" alt="" />分享链接给微信好友
            </li>
            <li>
              <img
                src="@/assets/imgs/zdd/second.png"
                alt=""
              />对方注册并成功发放1个证书
            </li>
            <li>
              <img
                src="@/assets/imgs/zdd/third.png"
                alt=""
              />系统自动为双方账户增加20个文件份数
            </li>
          </ul>
        </div>
        <div class="close">
          <van-icon name="close" @click="close" />
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
  import wechatApi from '@/api/user/wechat'
  import { mapState } from 'vuex'

  export default {
    name: 'zddInvitPopup',
    props: {
      show: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {}
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
        userInfo: state => state.user.userInfo,
      }),
    },
    watch: {
      show: {
        handler(val) {},
        deep: true,
        immediate: true,
      },
    },
    created() {},
    mounted() {
      this.getWxConfig()
    },
    methods: {
      close() {
        this.$emit('close')
      },
      async getWxConfig() {
        try {
          const wxConfig = await wechatApi.signURL()
          this.initWxConfig(wxConfig)
        } catch (error) {
          console.error(error)
        }
      },
      initWxConfig(wxConfig) {
        const { appId, timestamp, nonceStr, signature } = wxConfig
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'], // 必填，需要使用的JS接口列表
        })
        const url = `${this.systemInfo.hostH5}zdd/invitepublic?comId=${this.userInfo.comId}`
        this.initWxShare(url)
      },
      initWxShare(url) {
        window.wx.ready(() => {
          console.log('wx ready')
          window.wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'],
            success: res => {
              console.log('check js api', res)
            },
          })
          // 需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData({
            title: `邀请你使用「职袋袋」，一起守护职场信用吧~`, // 分享标题
            desc: `在职袋袋查档、建档、发高光证书，快速识别人才、激发团队活力，双方各得100元充值额度`, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://h5.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            success() {
              // 设置成功
              console.log('share success')
            },
            cancel() {
              console.log('share cancel')
            },
            fail(res) {
              console.log('share fail', res)
            },
          })

          // 即将废弃
          window.wx.onMenuShareAppMessage({
            title: `邀请你使用「职袋袋」，一起守护职场信用吧~`, // 分享标题
            desc: `在职袋袋查档、建档、发高光证书，快速识别人才、激发团队活力，双方各得100元充值额度`, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
              // 用户点击了分享后执行的回调函数
              console.log('share success 废弃接口')
            },
            cancel() {
              console.log('share cancel 废弃接口')
            },
            fail(res) {
              console.log('share fail 废弃接口')
            },
          })
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .zddInvitPopup {
    z-index: 99;
    .wrapper {
      width: 100%;
      height: 100%;
      padding: 11px 19px;
      box-sizing: border-box;
      .handForm {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 25px;
        .hand {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 12px;
          img {
            width: 39px;
            margin-bottom: 5px;
          }
        }
      }
      .contentForm {
        display: flex;
        flex-wrap: wrap;
        width: 337px;
        border-radius: 12px;
        .invitBg {
          width: 100%;
          height: 111px;
          display: flex;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          width: 100%;
          background: #fff;
          padding: 25px 33px 33px;
          box-sizing: border-box;
          border-radius: 0 0 12px 12px;
          .title {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #df5849;
            line-height: 23px;
            margin-bottom: 8px;
          }
          .time {
            font-size: 13px;
            font-family: PingFang, PingFang-SC;
            font-weight: SC;
            text-align: left;
            color: #111a34;
            line-height: 23px;
            margin-bottom: 22px;
          }
          .process {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 13px;
            font-family: PingFang, PingFang-SC;
            font-weight: SC;
            text-align: left;
            color: #111a34;
            line-height: 23px;
            li {
              img {
                width: 18px;
                margin-right: 6px;
              }
            }
            li + li {
              margin-top: 13px;
            }
          }
        }
        .close {
          width: 100%;
          margin-top: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          .van-icon {
            font-size: 29px;
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
